<template>
  <div>
    <section
      id="account-section"
      class="section section-secondary jpadding jpadding-20"
    >
      <div class="jcard jcard-main jcard-nohover">
        <div class="card-title">
          <h3>Security</h3>
        </div>

        <!-- INFO -->
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="password">
                <i class="fa fa-lock"></i> Current Password
              </label>
              <input
                id="password"
                type="password"
                class="form-control"
                v-model="currentPassword"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="new-password">
                <i class="fa fa-lock"></i> New Password
              </label>
              <input
                id="new-password"
                type="password"
                class="form-control"
                v-model="newPassword"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="password-check">
                <i class="fa fa-lock"></i> Repeat Password
              </label>
              <input
                id="password-check"
                type="password"
                class="form-control"
                v-model="newPasswordCheck"
              />
            </div>
          </div>
        </div>
        <b-alert v-if="!validPassword" show variant="danger"
          ><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
        <div class="btn-container text-center mt-4 mb-4">
          <button key="check"
            v-if="!isLoading"
            class="jbtn jbtn-blue"
            value="Add"
            @click="initUpdatePassword"
          >
           <i class="fa fa-check"></i> Update
          </button>

          <button v-else class="jbtn jbtn-blue" key="spinner">
            <i class="fad fa-spinner-third fa-spin" ></i>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
// import C from "@/constants";
import toast from "@/assets/js/toast";

export default {
  name: "Security",
  data() {
    return {
      isLoading: false,
      currentPassword: "",
      newPassword: "",
      newPasswordCheck: " ",

      validPassword: true,
      validationMsg: "",
    };
  },
  methods: {
    initUpdatePassword() {
      if (!this.isLoading) {
        this.isLoading = true;
        if (!this.validate()) {
          this.isLoading = false;
          return;
        }
        this.reauthenticate();
      }
    },
    updatePassword() {
      var user = firebase.auth().currentUser;
      var newPassword = this.newPassword;

      user
        .updatePassword(newPassword)
        .then(() => {
          this.isLoading = false;
          toast.success("Password updated.");
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error(error.message);
        });
    },
    reauthenticate() {
      var user = firebase.auth().currentUser;
      var credentials = firebase.auth.EmailAuthProvider.credential(
        user.email,
        this.currentPassword
      );

      user
        .reauthenticateWithCredential(credentials)
        .then(() => {
          //change password after re-authenticate
          this.updatePassword();
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error(error.message);
        });
    },
    validate() {
      this.validationMsg = "";
      //Check password
      if (this.newPassword.length < 8) {
        this.validPassword = false;
        this.validationMsg =
          "Your new password is too short (8 characters min.)";
        return false;
      }
      //Check confirm password
      if (this.newPassword != this.newPasswordCheck) {
        this.validPassword = false;
        this.validationMsg = "Your passwords don't match";
        return false;
      }
      //Check confirm password
      if (this.newPassword === this.currentPassword) {
        this.validPassword = false;
        this.validationMsg = "New password can't be the same as current one";
        return false;
      }
      this.validationMsg = "";
      this.validPassword = true;
      return true;
    },
  },
};
</script>

<style scoped></style>
